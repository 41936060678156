
export default {
  props: {
    classname: {
      required: true,
      type: String
    },
    url: {
      required: true,
      type: String
    },
    text: {
      required: true,
      type: String
    }
  }
}
