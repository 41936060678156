
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PickupTitle extends Vue {
  @Prop({
    type: String,
    required: true
  })
  private title!: string

  @Prop({
    type: String,
    required: false
  })
  private moreUrl!: string

  @Prop({
    type: Boolean,
    default: false,
    required: false
  })
  private white!: boolean
}
