
import { Component, Prop, Vue } from 'vue-property-decorator'
import ArticleCard from '~/components/shared/card/ArticleCard.vue'

@Component({
  components: {
    ArticleCard
  }
})
export default class TopCarousel extends Vue {
  @Prop({
    type: Array,
    required: true
  })
  private items!: any[]

  @Prop({
    type: String,
    required: true
  })
  private type!: string

  private isArticle = this.type === 'article'

  private get carouselSetting() {
    const settings: any = {
      slidesPerView: this.isArticle ? 1.3 : 'auto',
      spaceBetween: 24,
      loop: this.isArticle,
      centeredSlides: this.isArticle,
      speed: 1000,
      touchRatio: 1,
      touchReleaseOnEdges: false,
      watchSlidesVisibility: true,
      observeParents: true,
      observer: true,
      slideToClickedSlide: false,
      navigation: {
        prevEl: '.swiper-button-prev',
        nextEl: '.swiper-button-next'
      },
      threshold: 10,
      touchStartPreventDefault: false,
      breakpoints: {
        767: {
          slidesPerView: 'auto',
          spaceBetween: this.isArticle ? 64 : 32
        }
      }
    }
    if (this.isArticle) settings.pagination = { el: '.swiper-pagination' }
    return settings
  }
}
