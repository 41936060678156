export default {
  head() {
    return {
      title: this.headBase.title + '｜就活・転職活動に役立つキャリア学習サイト',
      link: this.headBase.link,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.headBase.description
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.headBase.keywords
        },

        // ogp
        {
          hid: 'og:title',
          property: 'og:title',
          content: this.headBase.title
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: process.env.BASE_URL + this.$route.fullPath
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: this.headBase.description
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.headBase.ogpImage
        },

        // SNS
        {
          hid: 'twitter:title',
          name: 'twitter:title',
          content: this.headBase.title
        },
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          content: this.headBase.description
        },
        {
          hid: 'twitter:domain',
          name: 'twitter:domain',
          content: 'talent-book.jp'
        },
        {
          hid: 'twitter:image:src',
          name: 'twitter:image:src',
          content: this.headBase.ogpImage
        },
        {
          hid: 'twitter:card',
          name: 'twitter:card',
          content: this.headBase.twitterCard ?? 'summary_large_image'
        }
      ]
    }
  }
}
