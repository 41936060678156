export function karteTracker() {
  if (
    process.env.NODE_ENV !== 'production' &&
    process.env.NODE_ENV !== 'staging'
  ) {
    return
  }

  function searchTrackerLoop(loopLimit, i) {
    if (i <= loopLimit) {
      setTimeout(() => {
        if ('tracker' in window) {
          // eslint-disable-next-line
          tracker.view()
          return false
        } else {
          searchTrackerLoop(loopLimit, ++i)
        }
      }, 300)
    }
  }

  try {
    // eslint-disable-next-line
    tracker.view()
  } catch (e) {
    // eslint-disable-next-line
    if ('tracker' in window) {
      // eslint-disable-next-line
      tracker.view()
    } else {
      // tracker確認できなければ300ms毎に再確認(max3回)
      searchTrackerLoop(3, 0)
    }
  }
}
