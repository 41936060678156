
import { Component, Prop, Vue } from 'vue-property-decorator'
import CarouselContents from '~/components/shared/CarouselContents.vue'
import LoadingSpinner from '~/components/shared/LoadingSpinner.vue'
import MixinMemberId from '~/components/mixins/MixinMemberId'
import authSetting from '~/config/authSetting'
const camelcaseKeys = require('camelcase-keys')

@Component({
  components: {
    CarouselContents,
    LoadingSpinner
  },
  mixins: [MixinMemberId]
})
export default class RecommendedContents extends Vue {
  @Prop({
    type: String,
    required: false
  })
  private display: string

  @Prop({
    type: String,
    required: false
  })
  private used: string

  private recommendAi: any[] | null = null
  private showLoading: boolean = true
  private memberId!: number

  private async mounted() {
    const config = {
      auth: authSetting,
      withCredentials: true
    }

    const viewedArticles =
      JSON.parse(sessionStorage.getItem('viewedArticles')) || []
    let recommendedUrl =
      process.env.API_URL + '/recommended_contents/ai?return_num=10'

    // ログインしていない場合のみ、target_idsをURLに追加
    if (!this.memberId && viewedArticles.length > 0) {
      const targetIds = viewedArticles.join(',')
      recommendedUrl += `&target_ids=${targetIds}`
    }

    try {
      const response = await this.$axios.$get(recommendedUrl, config)
      const aiData = camelcaseKeys(response, { deep: true })
      this.recommendAi = aiData.aiRecommendedContents || []
      this.$emit('recommendation-response', this.recommendAi)
    } catch (err) {
      this.recommendAi = []
    } finally {
      this.showLoading = false
    }
  }
}
