
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SectionTitle extends Vue {
  @Prop({
    type: String,
    required: true
  })
  private title!: string

  // memberTopで付与されるclass
  @Prop({
    type: Boolean,
    required: false
  })
  private memberTop!: boolean

  @Prop({
    type: Boolean,
    default: false,
    required: false
  })
  private secondalySection: boolean

  // h1~6のtag指定
  @Prop({
    type: Number,
    default: 2,
    required: false
  })
  private level?: number

  private get headingTagLevel() {
    return `h${this.level}`
  }
}
