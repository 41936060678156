import { karteTracker } from '~/plugins/karte'

export default {
  data() {
    return {
      reloadFlag: null
    }
  },
  mounted() {
    try {
      // sessionStorageのreloadFlag取得
      this.reloadFlag = sessionStorage.getItem('reload')
      window.addEventListener('beforeunload', () => {
        // リロードされた場合はsessionStorageにreloadFlagを保存
        this.setStorageRloadFlag()
      })
    } catch {
      // webStorageAPIが利用できない場合はreturn
      return false
    } finally {
      this.$nextTick(() => {
        if (!this.reloadFlag) karteTracker()
      })
    }
  },
  beforeDestroy() {
    try {
      // EventListenerとreloadFlagの削除
      window.removeEventListener('beforeunload', () => {
        this.setStorageRloadFlag()
      })
      sessionStorage.removeItem('reload')
    } catch {
      // webStorageAPIが利用できない場合はreturn
      return false
    }
  },
  methods: {
    setStorageRloadFlag() {
      this.reloadFlag = 1
      sessionStorage.setItem('reload', this.reloadFlag)
    }
  }
}
