
import { Component, Vue } from 'vue-property-decorator'
import UniversalCookie from 'universal-cookie'

@Component
export default class CookiePolicyDialog extends Vue {
  private close(): void {
    this.$emit('close')

    const cookies = new UniversalCookie()
    const expirationDate = this.$dayjs(new Date()).add(1, 'year').toDate() // 1年後に設定
    cookies.set('read_cookie_policy', true, { expires: expirationDate })
  }
}
