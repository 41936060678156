
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class LoadingSpinner extends Vue {
  @Prop({
    type: String,
    required: false
  })
  private classname: string

  @Prop({
    type: Boolean,
    required: false
  })
  private loadingtext!: boolean
}
